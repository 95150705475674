/*
 * @Author: Try
 * @Date: 2023-10-10 09:53:59
 * @LastEditTime: 2024-11-11 14:28:51
 * @LastEditors: Seven
 * @FilePath: \shulong_tenant_pc\src\enums\approval.ts
 * @Description: 头部注释配置模板
 */
export var EApprovalStatus;
(function (EApprovalStatus) {
    EApprovalStatus[EApprovalStatus["notCommitted"] = 0] = "notCommitted";
    EApprovalStatus[EApprovalStatus["pedding"] = 1] = "pedding";
    EApprovalStatus[EApprovalStatus["refused"] = 2] = "refused";
    EApprovalStatus[EApprovalStatus["pass"] = 3] = "pass";
    EApprovalStatus[EApprovalStatus["revoke"] = 4] = "revoke";
})(EApprovalStatus || (EApprovalStatus = {}));
// 投标状态
export var EBidStatus;
(function (EBidStatus) {
    EBidStatus[EBidStatus["notCommitted"] = 0] = "notCommitted";
    EBidStatus[EBidStatus["underReview"] = 1] = "underReview";
    EBidStatus[EBidStatus["rejected"] = 2] = "rejected";
    EBidStatus[EBidStatus["passed"] = 3] = "passed";
})(EBidStatus || (EBidStatus = {}));
/*
  审批类型  都是唯一的
  记得去配置通知
  src\hooks\useNotice.ts
*/
export var EApprovalProcessType;
(function (EApprovalProcessType) {
    EApprovalProcessType[EApprovalProcessType["tender"] = 2] = "tender";
    EApprovalProcessType[EApprovalProcessType["tenderChange"] = 47] = "tenderChange";
    EApprovalProcessType[EApprovalProcessType["tenderDoc"] = 12] = "tenderDoc";
    EApprovalProcessType[EApprovalProcessType["assure"] = 13] = "assure";
    EApprovalProcessType[EApprovalProcessType["tenderRefund"] = 16] = "tenderRefund";
    EApprovalProcessType[EApprovalProcessType["designChange"] = 17] = "designChange";
    EApprovalProcessType[EApprovalProcessType["projectContract"] = 15] = "projectContract";
    EApprovalProcessType[EApprovalProcessType["visaProject"] = 19] = "visaProject";
    EApprovalProcessType[EApprovalProcessType["collection"] = 20] = "collection";
    EApprovalProcessType[EApprovalProcessType["projectCost"] = 18] = "projectCost";
    EApprovalProcessType[EApprovalProcessType["projectCostChange"] = 49] = "projectCostChange";
    EApprovalProcessType[EApprovalProcessType["meeting"] = 21] = "meeting";
    EApprovalProcessType[EApprovalProcessType["constructionLog"] = 22] = "constructionLog";
    EApprovalProcessType[EApprovalProcessType["budget"] = 23] = "budget";
    EApprovalProcessType[EApprovalProcessType["budgetChange"] = 48] = "budgetChange";
    EApprovalProcessType[EApprovalProcessType["projectPayment"] = 4] = "projectPayment";
    EApprovalProcessType[EApprovalProcessType["projectOutputValue"] = 26] = "projectOutputValue";
    EApprovalProcessType[EApprovalProcessType["laborContract"] = 25] = "laborContract";
    EApprovalProcessType[EApprovalProcessType["costChange"] = 24] = "costChange";
    EApprovalProcessType[EApprovalProcessType["majorPackage"] = 28] = "majorPackage";
    EApprovalProcessType[EApprovalProcessType["machineryLeaseContract"] = 27] = "machineryLeaseContract";
    EApprovalProcessType[EApprovalProcessType["teamSettlement"] = 29] = "teamSettlement";
    EApprovalProcessType[EApprovalProcessType["retentionMoney"] = 30] = "retentionMoney";
    EApprovalProcessType[EApprovalProcessType["materialRequisition"] = 33] = "materialRequisition";
    EApprovalProcessType[EApprovalProcessType["pickLists"] = 41] = "pickLists";
    EApprovalProcessType[EApprovalProcessType["procurementContract"] = 34] = "procurementContract";
    EApprovalProcessType[EApprovalProcessType["procurementManagement"] = 32] = "procurementManagement";
    EApprovalProcessType[EApprovalProcessType["purchaseOrder"] = 35] = "purchaseOrder";
    EApprovalProcessType[EApprovalProcessType["warehouseCheck"] = 36] = "warehouseCheck";
    EApprovalProcessType[EApprovalProcessType["warehouseAllocate"] = 38] = "warehouseAllocate";
    EApprovalProcessType[EApprovalProcessType["warehouseReclaim"] = 40] = "warehouseReclaim";
    EApprovalProcessType[EApprovalProcessType["materialAcceptance"] = 37] = "materialAcceptance";
    EApprovalProcessType[EApprovalProcessType["purchaseReturn"] = 39] = "purchaseReturn";
    EApprovalProcessType[EApprovalProcessType["financeLease"] = 44] = "financeLease";
    EApprovalProcessType[EApprovalProcessType["payWarrant"] = 46] = "payWarrant";
    EApprovalProcessType[EApprovalProcessType["materialReturn"] = 42] = "materialReturn";
    EApprovalProcessType[EApprovalProcessType["materialRejection"] = 51] = "materialRejection";
    EApprovalProcessType[EApprovalProcessType["reimbursement"] = 45] = "reimbursement";
    EApprovalProcessType[EApprovalProcessType["purchaseRequisition"] = 50] = "purchaseRequisition";
    EApprovalProcessType[EApprovalProcessType["settlementManagement"] = 43] = "settlementManagement";
    EApprovalProcessType[EApprovalProcessType["generalHandleStorage"] = 53] = "generalHandleStorage";
    EApprovalProcessType[EApprovalProcessType["wasteAllocate"] = 54] = "wasteAllocate";
    EApprovalProcessType[EApprovalProcessType["wasteDisposal"] = 55] = "wasteDisposal";
    EApprovalProcessType[EApprovalProcessType["dimission"] = 56] = "dimission";
    EApprovalProcessType[EApprovalProcessType["personnelChange"] = 57] = "personnelChange";
    EApprovalProcessType[EApprovalProcessType["roster"] = 58] = "roster";
    EApprovalProcessType[EApprovalProcessType["contractManagement"] = 59] = "contractManagement";
    EApprovalProcessType[EApprovalProcessType["DocumentDorrowingAndReturning"] = 60] = "DocumentDorrowingAndReturning";
    EApprovalProcessType[EApprovalProcessType["rewards"] = 62] = "rewards";
    EApprovalProcessType[EApprovalProcessType["regularManagement"] = 61] = "regularManagement";
    EApprovalProcessType[EApprovalProcessType["vehicleRegistration"] = 63] = "vehicleRegistration";
    EApprovalProcessType[EApprovalProcessType["vehicleContract"] = 65] = "vehicleContract";
    EApprovalProcessType[EApprovalProcessType["leaveApplication"] = 64] = "leaveApplication";
    EApprovalProcessType[EApprovalProcessType["officialApplication"] = 66] = "officialApplication";
    EApprovalProcessType[EApprovalProcessType["vehicleApplication"] = 67] = "vehicleApplication";
    EApprovalProcessType[EApprovalProcessType["businessApplication"] = 68] = "businessApplication";
    EApprovalProcessType[EApprovalProcessType["paymentApplication"] = 69] = "paymentApplication";
    EApprovalProcessType[EApprovalProcessType["paymentFromPartyA"] = 70] = "paymentFromPartyA";
    EApprovalProcessType[EApprovalProcessType["partyADepositReceived"] = 71] = "partyADepositReceived";
    EApprovalProcessType[EApprovalProcessType["partyBDepositReceived"] = 73] = "partyBDepositReceived";
    EApprovalProcessType[EApprovalProcessType["refundToPartyB"] = 74] = "refundToPartyB";
    EApprovalProcessType[EApprovalProcessType["reimbursementApplication"] = 72] = "reimbursementApplication";
    EApprovalProcessType[EApprovalProcessType["payCertificate"] = 75] = "payCertificate";
    EApprovalProcessType[EApprovalProcessType["payMoney"] = 76] = "payMoney";
    EApprovalProcessType[EApprovalProcessType["partyBCollectWarrantyDeposit"] = 77] = "partyBCollectWarrantyDeposit";
    EApprovalProcessType[EApprovalProcessType["partyBPayWarrantyDeposit"] = 78] = "partyBPayWarrantyDeposit";
    EApprovalProcessType[EApprovalProcessType["partyAReclaimWarrantyDeposit"] = 79] = "partyAReclaimWarrantyDeposit";
    EApprovalProcessType[EApprovalProcessType["receipt"] = 80] = "receipt";
    EApprovalProcessType[EApprovalProcessType["materiaPlan"] = 84] = "materiaPlan";
    EApprovalProcessType[EApprovalProcessType["wbsProject"] = 81] = "wbsProject";
    EApprovalProcessType[EApprovalProcessType["targetCosting"] = 82] = "targetCosting";
    EApprovalProcessType[EApprovalProcessType["equipmentPlan"] = 83] = "equipmentPlan";
    EApprovalProcessType[EApprovalProcessType["revenueContract"] = 86] = "revenueContract";
    EApprovalProcessType[EApprovalProcessType["procureContract"] = 85] = "procureContract";
    EApprovalProcessType[EApprovalProcessType["revenueContractMetaphase"] = 87] = "revenueContractMetaphase";
    EApprovalProcessType[EApprovalProcessType["purchaseSettlement"] = 88] = "purchaseSettlement";
    EApprovalProcessType[EApprovalProcessType["equipmentContract"] = 89] = "equipmentContract";
    EApprovalProcessType[EApprovalProcessType["subcontract"] = 90] = "subcontract";
    EApprovalProcessType[EApprovalProcessType["otherContracts"] = 91] = "otherContracts";
    EApprovalProcessType[EApprovalProcessType["otherContractMetaphase"] = 92] = "otherContractMetaphase";
    EApprovalProcessType[EApprovalProcessType["subcontractMetaphase"] = 95] = "subcontractMetaphase";
    EApprovalProcessType[EApprovalProcessType["depositPayment"] = 96] = "depositPayment";
    EApprovalProcessType[EApprovalProcessType["returnMargin"] = 97] = "returnMargin";
    EApprovalProcessType[EApprovalProcessType["paymentRegistration"] = 93] = "paymentRegistration";
    EApprovalProcessType[EApprovalProcessType["transactionRecord"] = 94] = "transactionRecord";
    EApprovalProcessType[EApprovalProcessType["collectMargin"] = 100] = "collectMargin";
    EApprovalProcessType[EApprovalProcessType["collectReturnMargin"] = 101] = "collectReturnMargin";
    EApprovalProcessType[EApprovalProcessType["registerOfReceipt"] = 99] = "registerOfReceipt";
    EApprovalProcessType[EApprovalProcessType["invoicingRegistration"] = 98] = "invoicingRegistration";
    EApprovalProcessType[EApprovalProcessType["purchaseStorage"] = 102] = "purchaseStorage";
    EApprovalProcessType[EApprovalProcessType["materialStorage"] = 103] = "materialStorage";
    EApprovalProcessType[EApprovalProcessType["materialDelivery"] = 104] = "materialDelivery";
    EApprovalProcessType[EApprovalProcessType["projectConstructionSchedule"] = 105] = "projectConstructionSchedule";
    EApprovalProcessType[EApprovalProcessType["laborFeeRegistrationForm"] = 106] = "laborFeeRegistrationForm";
    EApprovalProcessType[EApprovalProcessType["materialFeeRegisterForm"] = 107] = "materialFeeRegisterForm";
    EApprovalProcessType[EApprovalProcessType["machineryFeeRegisterForm"] = 108] = "machineryFeeRegisterForm";
    EApprovalProcessType[EApprovalProcessType["managementFeeRegisterForm"] = 109] = "managementFeeRegisterForm";
    EApprovalProcessType[EApprovalProcessType["financialFeeRegisterForm"] = 110] = "financialFeeRegisterForm";
    EApprovalProcessType[EApprovalProcessType["expenseApplication"] = 112] = "expenseApplication";
    EApprovalProcessType[EApprovalProcessType["expenseApplication1"] = 113] = "expenseApplication1";
})(EApprovalProcessType || (EApprovalProcessType = {}));
/*
菜单类型
*/
export var EApprovalDuties;
(function (EApprovalDuties) {
    EApprovalDuties[EApprovalDuties["qualityManage"] = 4] = "qualityManage";
    EApprovalDuties[EApprovalDuties["safetyManagement"] = 5] = "safetyManagement";
    EApprovalDuties[EApprovalDuties["sceneManage"] = 6] = "sceneManage";
    EApprovalDuties[EApprovalDuties["costManagement"] = 7] = "costManagement";
    EApprovalDuties[EApprovalDuties["materialManagement"] = 8] = "materialManagement";
    EApprovalDuties[EApprovalDuties["machineryManagement"] = 9] = "machineryManagement";
    EApprovalDuties[EApprovalDuties["laborManagement"] = 10] = "laborManagement";
    EApprovalDuties[EApprovalDuties["dataManagement"] = 11] = "dataManagement";
    EApprovalDuties[EApprovalDuties["afterSalesManagement"] = 1] = "afterSalesManagement";
    EApprovalDuties[EApprovalDuties["materialAcceptance"] = 37] = "materialAcceptance";
})(EApprovalDuties || (EApprovalDuties = {}));
/**
 * @description: 条件分支
 */
const conditionalBranch = {
    PAYMENT: [
        {
            columnId: '1',
            columnName: 'amount',
            columnType: 'Double',
            fixedDownBoxValue: '',
            laber: '付款金额',
            fieldType: 'number',
        },
    ],
    APPROVED: { value: 'approved', label: '已批准' },
    REJECTED: { value: 'rejected', label: '已拒绝' },
};
